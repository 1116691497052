import * as React from 'react'
import {
  Box,
  VStack,
  Heading,
  Link,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Img
} from '@chakra-ui/react'
import { CtaSimple } from '../components/ctaSimple/'
import { Link as GatsbyLink } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { NavBar } from '../components/navbar'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'
import HexStrokeOpacitySmall from '../assets/svg/flair/stroke-hex-opacity-small.svg'
import HexStrokeOpacity from '../assets/svg/flair/stroke-hex-opacity.svg'

const AccordionBox = ({ title, body }) => {
  return (
    <AccordionItem borderRadius="8px" bg="utility.alpha10" borderY="0" mb="10">
      <Heading as="h4">
        <AccordionButton fontSize={{ base: 'xl', md: '2xl'}}>
          <Box flex="1" textAlign="left" fontWeight="600" py="4" px={{ base: 0, md: '4'}}>
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Heading>
      <AccordionPanel p="8" fontSize="lg" whiteSpace="pre-line">
        {body}
      </AccordionPanel>
    </AccordionItem>
  )
}

const FAQPage = () => (
  <Layout>
    <Seo title="FAQ" />
    <Box
      bgImage={fractalBg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <NavBar />
      <CtaSimple title={<strong>Carbon FAQs</strong>} titleSize="3xl" />
    </Box>
    <Box pos="relative" overflow="hidden">
      <Img
        pos="absolute"
        top="-13%"
        left="3%"
        w="240px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top="5%"
        right="20%"
        w="60px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacitySmall}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="47%"
        left="48%"
        w="96px"
        height="auto"
        opacity="0.25"
        src={HexStrokeOpacitySmall}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        top="65%"
        left="2%"
        w="120px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="1.5%"
        left="18%"
        w="40px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacitySmall}
        alt="Hexagon Icon"
      />
      <Img
        pos="absolute"
        bottom="5%"
        right="5%"
        w="160px"
        height="auto"
        opacity="0.5"
        src={HexStrokeOpacity}
        alt="Hexagon Icon"
      />
      <Box
        as="section"
        maxW={{
          base: 'xl',
          md: '7xl',
        }}
        mx="auto"
        px={{
          base: 4,
          md: 8,
        }}
        py={{
          base: 10,
          md: 24,
        }}
      >
        <Heading as="h2" size="2xl" mb={12}>
          Learn more about CarbonQA
        </Heading>
        <VStack spacing={{ base: 5, md: 10, }}>
          <Accordion defaultIndex={[0]} allowMultiple w="100%">
            <AccordionBox
              title="How does CarbonQA work?"
              body={
                <>
                  We assign a team to learn the ins and outs of your project, and
                  start manually testing your app. We report bugs to your team
                  directly and we remain available as you continue development on
                  your project. We've formed long-term testing partnerships with
                  clients for years. We provide ongoing support, burst capacity, and
                  everything in between.
                  <br />
                  <br />
                  <Link
                    as={GatsbyLink}
                    textDecoration="underline"
                    to="/how-it-works"
                  >
                    Learn more about the CarbonQA experience here.
                  </Link>
                </>
              }
            />
            <AccordionBox
              title="Who is CarbonQA?"
              body={
                <>
                  CarbonQA is a branded product offering by{' '}
                  <Link
                    textDecoration="underline"
                    target="_blank"
                    href="https://synapsestudios.com/"
                  >
                    Synapse Studios
                  </Link>
                  , a premiere JavaScript consultancy. We've been developing
                  high-quality software for nearly 20 years, and we've had a lot of
                  success offering our QA team's services to companies who have
                  their own dev teams. CarbonQA brings our processes, techniques,
                  and some other fancy tricks all under one roof to provide an
                  incredibly valuable service geared towards startups.
                </>
              }
            />
            <AccordionBox
              title="How does CarbonQA save me money?"
              body="That's an easy one! Your devs are probably the most expensive part of your team. Any time they spend testing or ensuring things work perfectly is time they're not spending on principal development. And while automated testing can help, startups are usually moving fast enough that they can be burdensome early on. So CarbonQA provides affordable, US-based, trusted testers that get to know your product and work hand-in-hand to make it better."
            />
            <AccordionBox
              title="Why not use a crowdsourced QA solution?"
              body={
                <>
                  Crowdsourcing QA can have its place. But we've found that context
                  is <i>everything</i> in testing. When we get to know your product, it
                  makes us more effective. Our testing relationship is a
                  partnership, and not some random fling. (And what's more, our
                  testers go way beyond what crowdsourced testers are able to do.) We
                  talk with your devs directly in Slack, follow up on issues, work
                  within your release timelines, and anticipate your needs. It's a
                  completely different experience.
                  <br />
                  <br />
                  <Link
                    as={GatsbyLink}
                    textDecoration="underline"
                    to="/how-it-works"
                  >
                    Learn more about the CarbonQA experience here.
                  </Link>
                </>
              }
            />
            <AccordionBox
              title="How does CarbonQA bill?"
              body={
                <>
                  We charge a monthly subscription that ensures we have
                  “project-ready” testers: resources that have been trained on your
                  project and familiarized with your testing needs, there when you
                  need them. We bill per tester, per day they spend testing your
                  project. Your subscription includes your first few resource days
                  per month, as well as our training and onboarding process for
                  maintaining your project-ready team.
                  <br />
                  <br />
                  <Link
                    as={GatsbyLink}
                    textDecoration="underline"
                    to="/how-it-works"
                  >
                    Learn more about the CarbonQA experience here.
                  </Link>
                </>
              }
            />
            <AccordionBox
              title="What if I need more days? Or multiple testers?"
              body="Have an important release coming up and need some extra hands? Our goal is to always have spare capacity when you need it. You're always informed about any utilization beyond your subscription, and we only work additional days with your explicit approval. We’ll let you know the maximum number of concurrent testers we can accommodate on your project at a given time."
            />
            <AccordionBox
              title="Why daily billing?"
              body="Simplicity and momentum. Our typical work unit is a “resource day”: one tester working for the full day on your project. This allows for less context switching, better engagement, and higher responsiveness. And it keeps billing super simple, which is good for everyone. Some plans allow for requesting a half resource day, if you need that sort of flexibility."
            />
            <AccordionBox
              title="How do we start?"
              body={`It's simple: after we determine together that we're a fit, we begin our monthly engagement. You complete a very short introduction survey and we begin with a kick off meeting for the project with your product owner, our onboarding team, and a few of our testers.\nWe create a shared Slack channel, and determine where you want your bugs reported, and we get to work familiarizing ourselves with your app.\nOur team works alongside yours: we file issues, ask questions in Slack, create animated screen capture gifs to aid in reproducing issues, and coordinate with your team's release schedule.`}
            />
            <AccordionBox
              title="Who are the testers?"
              body="We exclusively hire US-based full-time testers to work out of our offices in person. Everyone has a strong command of English. They’re great communicators, with fantastic attention to detail. They'll get to know your project and develop a familiarity that takes them deeper than a random resource could ever get. If they ever leave, we ensure another tester is trained and ready to take their place."
            />
            <AccordionBox
              title="How flexible is scheduling? What if we don't need testing?"
              body={`Our base plan guarantees availability to utilize your monthly retainer days with three business days notice, but we’re usually able to accommodate a much quicker turnaround. Different Service Level Agreement options are available if you need more rapid responses, but this is atypical. Overage days are subject to availability. Our higher tier plans receive preference.\n\nIn cases where you don’t require testing, most plans allow you to pause your service and avoid paying the monthly subscription for that month. But if you require any resource days during a paused month, you'll pay the full amount.`}
            />
            <AccordionBox
              title="Shouldn't we just hire our own QA?"
              body={
                <>
                  Sure! If you have enough work for a full-time QA, and the time to
                  establish processes and best practice, you should absolutely hire
                  one. We find that our clients are a great fit when they:
                  <br />
                  <br />
                  <UnorderedList>
                    <ListItem>
                      Don't have time to recruit, interview, evaluate, hire, and
                      train a new resource (let alone a new role)
                    </ListItem>
                    <ListItem>
                      Want to realize immediate performance and quality gains from
                      their existing team
                    </ListItem>
                    <ListItem>
                      Don't want to add an FTE to their monthly burn (or provide
                      desk space, benefits, and everything else)
                    </ListItem>
                    <ListItem>
                      Don't have the systems and processes in place to onboard
                      someone into a new role
                    </ListItem>
                    <ListItem>
                      Are worried about keeping that person busy full-time
                    </ListItem>
                    <ListItem>Need flexibility to scale up OR down</ListItem>
                    <ListItem>
                      Recognize that their dev and product resources shouldn’t be
                      doing all of their testing
                    </ListItem>
                  </UnorderedList>
                </>
              }
            />
          </Accordion>
        </VStack>
      </Box>
    </Box>
    <CtaSimple
      title="Ready to unlock your team's full potential?"
      titleSize="xl"
      cta="Get Started"
      linkTo="/contact"
      showBg
    />
  </Layout>
)

export default FAQPage
